<template>
    <div>
        <h2>Words API</h2>
        <div class="tooltip">  
            <span class="flex m-2">
                <p class="ml-2" v-for="word in this.word" :key="word.id" @mouseover="wordFilter(word)">
                    {{word.v}}
                </p>   
            </span>                                                
            <span class="tooltiptext recon_tooptip pt-2 pr-5 pl-5 br-6" md-direction="top">
                <b class="text-black" style="font-size: 1rem;">{{this.$root.voice}}
                    <span v-for="(syn,i) in this.info" :key="syn.id" v-bind:class="[i < 1 ? 'show' : 'hidden']"><small class="pl-3">({{syn.partOfSpeech}})</small></span>
                    </b>
                <span style="display:flex">
                 <b class="text-black">Definition-: </b> <b class="text-black font-normal pb-0 font-600" v-for="(syn,i) in this.info" :key="syn.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">{{syn.definition}}, </b>
                </span>
                <span style="display:flex">
                    <b class="text-black">Synonyms-: </b> 
                    <span v-for="(syn,i) in this.info" :key="syn.id" v-bind:class="[i < 3 ? 'show' : 'hidden']">
                        <b class="text-black font-normal pb-0 font-600" v-for="sy in syn.synonyms" :key="sy.id">
                        {{sy}}, 
                        </b>
                    </span>
                </span>
                 
                <span style="text-align:left" class="flex text-black font-normal pb-1">
                    <b>Ex-:</b>
                    <span v-for="(syn,i) in this.info" :key="syn.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                        <p v-for="s in syn.examples" :key="s.id">{{s}}, </p>
                    </span>
                    
                </span>
                
                <p style="text-align:left;font-size: 1rem;font-weight:600" class="text-blue pb-0">Pronunciation</p>
                <b class="lan text-blue flex align-center" style="line-height:0;margin-top:5px;font-size: 1rem;">UK <span class="flex align-center text-black pl-3">{{this.$root.voice}}<span @click="UKVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;" ><Volume/></span></span></b>
                <b class="lan text-blue flex align-center" style="line-height:0;margin-top:7px;font-size: 1rem;">US <span class="flex align-center text-black pl-3">{{this.$root.voice}}<span @click="USVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;"><Volume/></span></span></b><br>
            </span>
        </div>
    </div>
</template>

<script>
import Volume from '../../../../assets/svg/volume.svg';
import axios from 'axios';
export default {
  components:{
      Volume
  },
  data() {
    return {
        info: null,
        value: '',
        results:[],
        voice: '',
        word:[
            {
                v:'Practice'
            },
            {
                v:'such'
            },
            {
                v:'sentences'
            },
             {
                v:'in'
            },
            {
                v:'your'
            },
            {
                v:'home'
            },
             {
                v:'or'
            },
            {
                v:'with'
            },
            {
                v:'your'
            }, {
                v:'friends'
            },
            {
                v:'then'
            },
            {
                v:'you'
            }, {
                v:'will'
            },
            {
                v:'learn'
            },
            {
                v:'English'
            }, 
            {
                v:'very'
            }, 
            {
                v:'quickly.'
            },
        ],
        synonyms: null,
        tooltipActive: true,
        API_BASE_URL: 'https://www.wordsapi.com/mashape/words/'
    }
  },
  computed: { 
  
  },
  mounted () {
     
  },
  methods:{
        wordFilter(val){
            const value = val.v
            console.log(value)
          this.tooltipActive = !this.tooltipActive;
          this.$root.voice = val.v;
           let config = {
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': 'Content-Length, X-JSON',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                }, 
            }
          axios
            .get(this.API_BASE_URL+value+"?when=2021-12-29T07:34:05.457Z&encrypted=8cfdb18be723919be89807bfe858bdbdaeb0280934fa90b8",config
            ).then(response => (
                this.info = response.data.results
            ))
            console.log(this.info)
        },
        USVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.$root.voice;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-US";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        },
        UKVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.$root.voice;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-GB";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        }
  },
  
 
};
</script>
<style>
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.lan svg{
    height: 22px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>